import * as React from "react"

const Head = ({ pageTitle }) => {
  const title = "Racqy Manager Platform"
  if (window.location.pathname.includes("/home")) {
    return <title>{title}</title>
  } else {
    return <title>{`${pageTitle} | ${title}`}</title>
  }
}

export default Head
