import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Grid, Typography } from "@material-ui/core"
import footerLogo from "../../../static/images/footerLog.png"
import CustomButton from "./CustomButton"
import fb from "../../images/fb.png"
import insta from "../../images/insta.png"
import tiktok from "../../images/tiktok.png"
import linkedin from "../../images/linkedin.png"
import { Link } from "gatsby"

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    fontFamily: "DM Sans",
    width: "100%",
    position: "absolute",
  },
  boxStyle: {
    color: "#979D9D",
    width: "100%",
    paddingTop: "70px",
    paddingBottom: "23px",
    background: "white",
    fontFamily: "DM Sans",
    paddingLeft: "6.5rem",
    paddingRight: "9rem",
  },
  footerLg: {
    width: "50px",
    height: "50px",
    margin: "5px",
  },
  footerHeading: {
    fontWeight: 600,
    color: "#211F1F",
    marginBottom: "5px",
    alignItems: "center",
  },
  firstCol: {
    fontSize: "12px",
  },
  secondCol: {
    fontSize: "14px",
    alignItems: "center",
    margin: "5px 0px",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  footerSocial: {
    width: "40px",
    height: "40px",
  },
  link: {
    textDecoration: "none",
    color: "#979D9D",
  },
}))

const Footer = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Grid
        className={classes.boxStyle}
        spacing={25}
        maxWidth="xs"
        justifyContent="space-between"
        container
      >
        <Grid item>
          <img src={footerLogo} alt="footerLogo" className={classes.footerLg} />
          <Typography className={classes.firstCol}>
            Stockholm, Sweden
          </Typography>
        </Grid>
        <Grid item>
          <Typography className={classes.footerHeading}>Company</Typography>
          <Link
            className={classes.link}
            target="_blank"
            to="https://racqy.com/about"
          >
            <Typography className={classes.secondCol}>About</Typography>
          </Link>
          {/* <Link className={classes.link} to="/sub-pages/press">
            <Typography className={classes.secondCol}>Press</Typography>
          </Link>
          <Link className={classes.link} to="/sub-pages/invest">
            <Typography className={classes.secondCol}>Invest</Typography>
          </Link> */}
        </Grid>
        <Grid item>
          <Typography className={classes.footerHeading}>Legal</Typography>
          <Link
            className={classes.link}
            target="_blank"
            to="https://racqy.com/terms-conditions"
          >
            <Typography className={classes.secondCol}>
              Terms and conditions
            </Typography>
          </Link>
          <Link
            className={classes.link}
            target="_blank"
            to="https://racqy.com/privacy-policy"
          >
            <Typography className={classes.secondCol}>
              Privacy policy
            </Typography>
          </Link>
          <Link
            className={classes.link}
            target="_blank"
            to="https://racqy.com/cookies-policy"
          >
            <Typography className={classes.secondCol}>
              Cookies policy
            </Typography>
          </Link>
        </Grid>
        <Grid item>
          <Typography className={classes.footerHeading}>Platform</Typography>
          <Link
            className={classes.link}
            t
            target="_blank"
            to="https://racqy.com/download"
          >
            <Typography className={classes.secondCol}>Download App</Typography>
          </Link>
          <Link
            className={classes.link}
            target="_blank"
            to="https://racqy.com/contact"
          >
            <Typography className={classes.secondCol}>Help</Typography>
          </Link>
        </Grid>
        <Grid>
          <CustomButton
            theme={"activity"}
            handleOnClick={() => window.open("https://racqy.com", "_blank")}
            text="Racqy players"
          />
        </Grid>
        <Grid xs={1}>
          <img src={insta} className={classes.footerSocial} alt="" />
          <img src={linkedin} className={classes.footerSocial} alt="" />
          <Grid>
            <img src={tiktok} className={classes.footerSocial} alt="" />
            <img src={fb} className={classes.footerSocial} alt="" />
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default Footer
