import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import logo from "../../images/drawer_ic.svg"
import logoText from "../../images/name_logo.svg"
import "../../index.css"
import { Grid, Typography } from "@material-ui/core"
import { createTheme, ThemeProvider } from "@material-ui/core/styles"
import laptopIcon from "../../images/laptop.svg"
import logoutIcon from "../../images/logout.svg"
import loginLogo from "../../images/Login.svg"
import { Link as GatsByLink } from "gatsby"
import StaticBanner from "./StaticBanner"
import "../../index.css"
import { useSelector } from "react-redux"

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    fontFamily: "DM Sans",
    width: "100%",
  },
  appBar: {
    backgroundColor: "#FFFFFF",
    zIndex: theme.zIndex.drawer + 1,
    height: "70px",
    "& .MuiToolbar-regular": {
      minHeight: "70px",
    },
    width: "100%",
  },
  appHeading: {
    marginLeft: "7px",
    marginTop: "7px",
    alignItems: "center",
  },
  name: {
    // marginLeft: "100px",
    // marginRight: "15px",
    // paddingRight: "15px",
    marginLeft: "130px",
    marginRight:"130px",
    padding: "0px"
  },
  signIn: {
    height: "27px",
  },
  signInText: {
    marginLeft: "10px",
    textDecoration: "none",
    color:  "#000000",
    fontWeight:"bold",
    fontSize:16,
    fontFamily:"medium"
  }
}))

const theme = createTheme({
  link: {
    fontFamily: ["DM Sans", "sans-serif"].join(","),
    fontSize: "18px",
  },
})

export default function AppBarNew() {
  const classes = useStyles()

  const isActive = useSelector(state => state.bannerSlice.isActive)

  return (
    <ThemeProvider theme={theme}>
      <StaticBanner />
      <div className={classes.root}>
        <AppBar
          elevation={0}
          position="fixed"
          className={`${classes.appBar} ${isActive && "margin-top-whole"}`}
        >
          <Toolbar className={classes.name}>
            <Grid
              justifyContent="space-between"
              display="flex"
              alignItems="center"
              container
            >
              <Grid item>
                <GatsByLink to="/home">
                  <div style={{
                    display:"flex",
                    justifyContent:"center",
                    alignItems:"center",
                    width:"100px"
                  }}>
                    <img style={{
                      width:"23px",
                      height:"23px"
                    }} src={logo} />
                    <img 
                    style={{
                      marginLeft:"10px",
                      height:"18px"
                    }} 
                    src={logoText} className={classes.appHeading} />
                  </div>
                </GatsByLink>
              </Grid>

              <Grid item style={{
                height:"100%",
                display:"flex",
                justifyContent:"flex-end"
              }} xs={2}>
                <GatsByLink style={{
                    display:"flex",
                    justifyContent:"center",
                    alignItems:"center",
                    textDecoration:"none",
                    width:"100px"
                  }}  to="/venue/login">
                  <img  className={classes.signIn} src={loginLogo}  />
                  <Typography className={classes.signInText}>Sign in</Typography>
                </GatsByLink>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </div>
    </ThemeProvider>
  )
}
