import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Button } from "@material-ui/core"
import { ButtonColor } from "../../helper/ButtonColor"
import { activityColors } from "../../helper/ButtonColor"

const useStyles = makeStyles(theme => ({
  customButton: {
    border: 0,
    borderRadius: 5,
    fontFamily: "DM Sans",
    color: "white",
    height: 38,
    width: 200,
    fontWeight: "bold",
    padding: "0 10px",
    textTransform: "none",
  },
  managerTheme: {
    background: `${ButtonColor}`,
    "&:hover": {
      background: `${ButtonColor}`,
    },
  },
  activityTheme: {
    background: `${activityColors}`,
    "&:hover": {
      background: `${activityColors}`,
    },
  }
}))

function CustomButton({ text, handleOnClick, theme }) {
  const classes = useStyles()
  return (
    <Button
      variant="contained"
      className={`${classes.customButton} ${theme == "manager" ? classes.managerTheme : classes.activityTheme}`}
      onClick={handleOnClick}
    >
      {text}
    </Button>
  )
}

export default CustomButton
